<template>
  <div>
    <el-popover
      v-model="isPopover"
      placement="bottom"
      trigger="click"
    >
      <el-form
        ref="form"
        v-loading="listLoading"
        :model="filterOptions"
        label-position="top"
      >
        <el-form-item
          :label="$t('activities.action_type')"
          prop="action_type"
        >
          <el-select
            v-model="filterOptions.action_type"
            :placeholder="$t('Form.placeholderSelect')+$t('activities.action_type')"
            filterable
            clearable
            multiple
            style="width:100%;"
          >
            <el-option
              v-for="item in actionOption"
              :key="item.value"
              :label="$t(`activities.${item.label}`)"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('activities.action_type')+ $t('activities.act_at')"
          prop="dateRange"
        >
          <el-date-picker
            v-model="filterOptions.dateRange"
            type="daterange"
            :start-placeholder="$t('dataList.startDate')"
            :end-placeholder="$t('dataList.endDate')"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        
        <el-form-item style="text-align: center;">
          <el-button
            class="buttonSecondaryReverse"
            @click="handleFilter"
          >
            {{ $t('general.Filter') }}
          </el-button>
        </el-form-item>
      </el-form>
      <el-button
        slot="reference"
        icon="el-icon-s-operation"
        type="text"
        class="headerTextColor"
      >
        {{ $t('general.Filter') }}
        <em class="el-icon-arrow-down el-icon--right" />
      </el-button>
    </el-popover>
    <template v-if="isFilterChanged">
      <el-divider direction="vertical" />
      <el-button
        size="small"
        icon="el-icon-close"
        class="buttonSecondaryReverse"
        @click="cleanFilter"
      >
        {{ $t('general.Clean') }}{{ $t('general.Filter') }}
      </el-button>
    </template>
  </div>
</template>

<script>
import { getActivitiesActions } from '@/api/activities'
export default {
  name: 'SearchFilter',
  props: {
    filterOptions: {
      type: Object,
      default: () => ({})
    },
    params: {
      type: Object,
      default: () => ({})
    },
    totalPage: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      listLoading: false,
      isPopover: false,
      isFilterChanged: false,
      actionOption: []
    }
  },
  mounted() {
    this.fetchActionList()
  },
  methods: {
    async fetchActionList() {
      try {
        const res = await getActivitiesActions()
        const data = res.data
        this.actionOption = data.map((item) => {
          const newObj = {
            value: item.values,
            label: item.values
          }
          return newObj
        })
      } catch (error) {
        console.error('getActivitiesActions-error: ', error)
      }
    },
    handleFilter() {
      this.listLoading = true
      this.isFilterChanged = true
      this.$emit('update')
      this.listLoading = false
      this.isPopover = false
    },
    cleanFilter() {
      this.listLoading = true
      this.isFilterChanged = false
      this.$refs['form'].resetFields()
      this.$emit('update')
      this.listLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select-group .el-select-dropdown__item {
  padding-left: 30px;
}
.el-select-group__wrap::after {
  display: none;
}
</style>
